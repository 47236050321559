<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-12 10:02:58
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-17 15:08:31
 * @FilePath: \demo_ele\src\views\userComponent\components\userDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div>
      <tableData :tableDetails="tableDetails" :cloData="cloData" :switchData="switchData"></tableData>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
      </pagination>
    </div>
  </div>
</template>
<script setup>
import { getUserDetails, } from "@/config/http"
import { ref, reactive, onMounted } from "vue"
import search from "@/components/search"
import pagination from "@/components/pagination.vue"
import tableData from "@/components/overallTable.vue"

const tableDetails = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const switchData = ref([
  {
    prop: "is_msg_alter",
    label: "是否离线消息通知",
    name: "is_msg_alter",
  },
  {
    prop: "is_msg_voice",
    label: "是否开启消息通知声音",
    name: "is_msg_voice ",
  },
  {
    prop: "is_msg_shock",
    label: "是否开启消息震动",
    name: "is_msg_shock ",
  },
  {
    prop: "is_creator",
    label: "是否创作者",
    name: "is_creator",
  },
  {
    prop: "is_featured",
    label: "是否精选作者",
    name: "is_featured",

  },
  {
    prop: "status",
    label: "账户状态",
    name: "status",
  },
])
const cloData = ref([
  {
    prop: "id",
    label: "ID"
  },
  {
    prop: "account",
    label: "账号"
  },
  {
    prop: "nick",
    label: "昵称"
  },
  {
    prop: "signature",
    label: "用户签名"
  },
  {
    prop: "fans",
    label: "粉丝数量"
  },
  {
    prop: "applaud",
    label: "认可获赞数量"
  },
  {
    prop: "follow",
    label: "关注数量"
  },
  {
    prop: "log",
    label: "日记数量"
  },
  {
    prop: "popularity",
    label: "受欢迎基数"
  },
])
const props = defineProps({
  detailId: {
    type: String,
    default: "",
  },
});
onMounted(() => {
  getList()
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getUserDetails(props.detailId, params).then((res) => {
    tableDetails.value = [res.data]
    painData.total = [res.data].length
  }).catch(() => {

  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const seaCher = (e) => { }
const rest = () => {
  getList()
}
</script>
<style lang="scss"></style>