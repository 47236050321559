<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-27 13:30:46
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-07-30 10:17:40
 * @FilePath: \demo_ele\src\views\articlePage\userAricle.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="wrapper">
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest"></search>
    </div>
    <div>
      <el-button type="primary" @click="setData" style="float:right">
        <el-icon>
          <Plus />
        </el-icon>
        <span>
          创建文章
        </span>
      </el-button>
    </div>
    <div>
      <tableDataDetails :tableData="tableData" @upData="upData" @detailData="detailData" @deleteData="deleteData"></tableDataDetails>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
    <div>
      <diaLog :dialogVisible="dialogVisible" :title="title" @close="close" :showStatus="showStatus" @submit="setDataClick" :upDatalist="upDatalist" @upSubmit="upSubmit" :detailList="detailList"></diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getArticleList, getArticleDetails, createArticle, upArticle, deleteArticle } from "@/config/http.js"
import { ElMessage, ElMessageBox } from 'element-plus'
import search from "@/components/search.vue"
import pagination from "@/components/pagination.vue"
import tableDataDetails from "./articleComponents/tableData.vue"
import diaLog from "./articleComponents/diaLog.vue"
const tableData = ref()
const dialogVisible = ref(false)
const title = ref()
const showStatus = ref()
const detailList = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const upDatalist = reactive({ //需要修改的内容字段
  id: "",
  key: "",
  title: "",
  content: "",
})
onMounted(() => {
  getList()
})
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getArticleList(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
const setData = () => { //创建
  title.value = "文章创建"
  dialogVisible.value = true
  showStatus.value = 1
}
const setDataClick = (e) => { //调用创建接口
  ElMessageBox.confirm(
    '此操作创建数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const params = {
      key: e.key,
      title: e.title,
      content: e.content,
    }
    createArticle(params).then((res) => {
      ElMessage({
        type: 'success',
        message: "创建成功",
      })
      dialogVisible.value = false
      getList()
      e.key = ""
      e.title = ""
      e.content = ""
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "创建失败",
      })
      dialogVisible.value = false
      e.key = ""
      e.title = ""
      e.content = ""
    })
  }).catch(() => { })
}
const upData = (e) => { //修改
  upDatalist.id = e.id
  upDatalist.key = e.key
  upDatalist.title = e.title
  upDatalist.content = e.content
  title.value = "文章修改"
  dialogVisible.value = true
  showStatus.value = 2
}
const upSubmit = (e) => {
  ElMessageBox.confirm(
    '此操作将修改该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const id = e.id
    const params = {
      key: e.key,
      title: e.title,
      content: e.content,
    }
    upArticle(id, params).then((res) => {
      ElMessage({
        type: 'success',
        message: "修改成功",
      })
      getList()
      dialogVisible.value = false
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "修改失败",
      })
      dialogVisible.value = false
    })
  }).catch(() => { })
}
const detailData = (e) => { //详情
  title.value = "详情"
  dialogVisible.value = true
  showStatus.value = 3
  getArticleDetails(e.id).then((res) => {
    console.log(res, 'res')
    detailList.value = [res.data]
  })
}
const deleteData = (e) => { //删除
  console.log(e)
  ElMessageBox.confirm(
    '此操作将删除该数据, 是否继续?', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    deleteArticle(e).then((res) => {
      ElMessage({
        type: 'success',
        message: "删除成功",
      })
      getList()
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: "删除失败",
      })
    })
  }).catch(() => { })
}
const close = (e) => {
  if (e == true) {
    dialogVisible.value = false
    getList()
  }
}
</script>
<style lang="scss">
</style>