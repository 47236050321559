<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-19 15:16:27
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-04-22 17:19:05
 * @FilePath: \demo_ele\src\views\authorPage\authorComponents\dataTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="margin-top: 20px">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column
        :prop="item.prop"
        :label="item.label"
        v-for="(item, index) in props.cloData"
        :key="index"
      >
      </el-table-column>
      <el-table-column prop="tableData" label="状态">
        <template #default="{ row }">
          {{ fn[row.status] }}
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="图片">
        <template #default="{ row }">
          <span
            v-if="row.images != null"
            v-for="(item, index) in JSON.parse(row.images)"
          >
            <img :src="item" alt="" style="width: 50px; height: 50px" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="用户信息">
        <template #default="{ row }">
          <span style="color: blue">用户ID : {{ row.client.id }}</span
          ><br />
          <span>用户昵称 : {{ row.client.nick }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="tableData"
        label="图片信息"
        v-if="props.showStatus"
      >
        <template #default="{ row }">
          <img :src="row.url" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps({
  tableData: {
    type: Object,
    default: [],
  },
  cloData: {
    type: Array,
    default: [],
  },
  showStatus: {
    type: String,
    default: "",
  },
});
const fn = ref({
  0: "待审核",
  1: "审核通过",
  2: "不通过",
});
</script>
<style lang="scss"></style>
