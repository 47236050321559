/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 15:33:17
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-07-29 10:46:35
 * @FilePath: \demo_ele\src\config\http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 导入axios实例
import service from '@/utils/request'
// get请求用params,post请求用data
//导出api方法
export function login (data) {
  return service({
    url: '/auth/login', //请求的路径 登录
    method: 'post',
    data: data,
  })
}
export function unLogin (data) { //退出登录
  return service({
    url: '/auth/logout',
    method: 'post',
    data: data,
  })
}
export function getUserList (params) { //客户列表
  return service({
    url: '/client',
    method: 'get',
    params: params,
  })
}
export function upUserList (id, data) { //客户修改
  return service({
    url: `/client/${id}`,
    method: 'put',
    data: data,
  })
}
export function getUserDetails (id, params) { //客户详情
  return service({
    url: `/client/${id}`,
    method: 'get',
    params: params,
  })
}
export function getUserWatchList (id, params) { //客户关注列表
  return service({
    url: `/client/${id}/follow`,
    method: 'get',
    params: params,
  })
}
export function getUserFanList (id, params) { //客户粉丝列表
  return service({
    url: `/client/${id}/fans`,
    method: 'get',
    params: params,
  })
}
export function getUserImageList (id, params) { //客户粉丝列表
  return service({
    url: `/client/${id}/images`,
    method: 'get',
    params: params,
  })
}
export function getUserDiary (id, params) { //用户日记
  return service({
    url: `/client/${id}/diaries`,
    method: 'get',
    params: params,
  })
}
export function putAuditShow (id) { //用户日记隐藏不展示
  return service({
    url: `/audit/diaries/${id}/hide`,
    method: 'put',
  })
}
export function getUserFavorites (id, params) { //用户收藏
  return service({
    url: `/client/${id}/collect`,
    method: 'get',
    params: params,
  })
}
export function getUserDiaryLikes (id, params) { //用户日记点赞列表
  return service({
    url: `/client/${id}/applaud`,
    method: 'get',
    params: params,
  })
}
export function getAuthorList (params) { //作者列表
  return service({
    url: '/creator',
    method: 'get',
    params: params,
  })
}
export function upAuthorList (id, data) { //作者修改
  return service({
    url: `/creator/${id}`,
    method: 'put',
    data: data,
  })
}
export function getAuthorReview (params) { //作者审核列表
  return service({
    url: '/audit/creator',
    method: 'get',
    params: params,
  })
}
export function upAuthorReview (id, data) { //修改审核列表
  return service({
    url: `/audit/creator/${id}`,
    method: 'put',
    data: data,
  })
}
export function TagList (params) { //标签列表
  return service({
    url: '/label',
    method: 'get',
    params: params,
  })
}
export function TagDetails (id, params) { //标签列表详情
  return service({
    url: `/label/${id}`,
    method: 'get',
    params: params,
  })
}
export function establishTag (data) { //创建标签
  return service({
    url: '/label',
    method: 'post',
    data: data,
  })
}
export function upBliShTag (id, data) { //修改标签
  return service({
    url: `/label/${id}`,
    method: 'put',
    data: data,
  })
}
export function deleteBliShTag (id, data) { //删除标签
  return service({
    url: `/label/${id}`,
    method: 'delete',
    data: data,
  })
}
export function getAuthorReviewList (params) { //作者审核列表
  return service({
    url: '/audit/creator',
    method: 'get',
    params: params,
  })
}
export function AuthorReview (id, data) { //作者审核
  return service({
    url: `/audit/creator/${id}`,
    method: 'put',
    data: data,
  })
}
export function getImagePhotoList (params) { //形象照审核列表
  return service({
    url: '/audit/images',
    method: 'get',
    params: params,
  })
}
export function ImagePhotoReview (id, data) { //形象照审核
  return service({
    url: `/audit/images/${id}`,
    method: 'put',
    data: data,
  })
}
export function getDiaryList (params) { //日记审核列表
  return service({
    url: '/audit/diaries',
    method: 'get',
    params: params,
  })
}
export function DiaryReview (id, data) { //日记审核
  return service({
    url: `audit/diaries/${id}`,
    method: 'put',
    data: data,
  })
}
export function getReportList (params) { //举报列表
  return service({
    url: '/report',
    method: 'get',
    params: params,
  })
}
export function getRobFeedback (params) { //问题反馈列表
  return service({
    url: '/feedback',
    method: 'get',
    params: params,
  })
}
export function getVersionList (params) { //平台审核版本列表
  return service({
    url: '/app-version',
    method: 'get',
    params: params,
  })
}
export function getVersionDetail (id, params) { //平台审核版本详情
  return service({
    url: `app-version/${id}`,
    method: 'get',
    params: params,
  })
}
export function creVersion (data) { //创建平台审核版本
  return service({
    url: '/app-version',
    method: 'post',
    data: data,
  })
}
export function upVersion (id, data) { //修改平台审核版本列表
  return service({
    url: `/app-version/${id}`,
    method: 'put',
    data: data,
  })
}
export function deleteVersion (id, params) { //删除平台审核版本列表
  return service({
    url: `/app-version/${id}`,
    method: 'delete',
    params: params,
  })
}
export function getChattingRecords (params) { //聊天记录
  return service({
    url: '/chat-record',
    method: 'get',
    params: params,
  })
}
export function ChattingRecall (id) { //聊天记录撤回
  return service({
    url: `/chat-record/recall/${id}`,
    method: 'put',
  })
}
export function deleteRecall (id) { //聊天记录删除
  return service({
    url: `/chat-record/${id}`,
    method: 'delete',
  })
}
export function getDiaryLists (params) { //日记列表
  return service({
    url: '/diaries',
    method: 'get',
    params: params,
  })
}
export function getImageList (params) { //形象照片列表
  return service({
    url: '/images',
    method: 'get',
    params: params,
  })
}
export function getBlackList (id, params) { //客户黑名单列表
  return service({
    url: `/client/${id}/blacks`,
    method: 'get',
    params: params,
  })
}
export function getUserBlockList (params) { //用户拉黑列表
  return service({
    url: '/blacks',
    method: 'get',
    params: params,
  })
}
export function getArticleList (params) { //文章列表
  return service({
    url: '/article',
    method: 'get',
    params: params,
  })
}
export function getArticleDetails (id, params) { //文章详情
  return service({
    url: `/article/${id}`,
    method: 'get',
    params: params,
  })
}
export function createArticle (data) { //创建文章
  return service({
    url: '/article',
    method: 'post',
    data: data,
  })
}
export function upArticle (id, data) { //修改文章
  return service({
    url: `/article/${id}`,
    method: 'put',
    data: data,
  })
}
export function deleteArticle (id, data) { //删除文章
  return service({
    url: `/article/${id}`,
    method: 'delete',
    data: data,
  })
}

export function getDictionariesList (params) { //字典列表
  return service({
    url: '/dictionary',
    method: 'get',
    params: params,
  })
}
export function getDictionariesDetails (id, params) { //字典详情
  return service({
    url: `/dictionary/${id}`,
    method: 'get',
    params: params,
  })
}
export function createDictionaries (data) { //创建字典
  return service({
    url: '/dictionary',
    method: 'post',
    data: data,
  })
}
export function upDictionaries (id, data) { //修改字典
  return service({
    url: `/dictionary/${id}`,
    method: 'put',
    data: data,
  })
}
export function deleteDictionaries (id, data) { //删除字典
  return service({
    url: `/dictionary/${id}`,
    method: 'delete',
    data: data,
  })
}

