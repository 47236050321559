<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-19 15:16:27
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-07-29 11:08:35
 * @FilePath: \demo_ele\src\views\authorPage\authorComponents\dataTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="margin-top: 20px">
    <el-table :data="props.tableData" border style="width: 100%">
      <el-table-column
        :prop="item.prop"
        :label="item.label"
        v-for="(item, index) in props.cloData"
        :key="index"
      >
      </el-table-column>
      <el-table-column prop="props.tableData" label="消息时间">
        <template #default="{ row }">
          <span>{{ formatDate(row.msg_time) }}</span
          ><br />
          <!-- <span>{{ row.msg_time }}</span> -->
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="消息内容">
        <template #default="{ row }">
          <div v-for="(item, index) in JSON.parse(row.msg_body)" :key="index">
            <span>消息类型 : </span><span>{{ fn[item.MsgType] }}</span
            ><br />
            <span>消息内容 : </span
            ><span v-if="item.MsgType == 'TIMTextElem'">{{
              item.MsgContent.Text
            }}</span>
            <span v-else>
              <!-- <div v-for="(ite,ind) in item.MsgContent.ImageInfoArray" :key="ind">
                <img :src="ite.URL" alt="" style="width:40px;height:40px;" lazy="true">
              </div> -->

              <span
                v-if="
                  !item.MsgContent.ImageInfoArray ||
                  !item.MsgContent.ImageInfoArray.length
                "
              ></span>
              <img
                v-else
                :src="item.MsgContent.ImageInfoArray[0].URL"
                alt=""
                style="width: 40px; height: 40px"
                lazy="true"
              />
              <!-- {{ item.MsgContent.ImageInfoArray  instanceof Array }} -->
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="发送人的信息">
        <template #default="{ row }">
          <span>ID : {{ row.send_user_info.id }}</span>
          <span> 昵称 : {{ row.send_user_info.nick }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="接收人的信息">
        <template #default="{ row }">
          <span>ID : {{ row.receive_user_info.id }}</span>
          <span> 昵称 : {{ row.receive_user_info.nick }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="recall(row.id)">撤回</el-button>
          <el-button type="danger" @click="deleteD(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, getCurrentInstance } from "vue";
const fn = ref({
  TIMTextElem: "文本消息",
  TIMImageElem: "图像消息",
});

const formatDate = () => {
  var getTime = new Date().getTime(); //获取到当前时间戳
  var time = new Date(getTime); //创建一个日期对象
  var year = time.getFullYear(); // 年
  var month = (time.getMonth() + 1).toString().padStart(2, "0"); // 月
  var date = time.getDate().toString().padStart(2, "0"); // 日
  var hour = time.getHours().toString().padStart(2, "0"); // 时
  var minute = time.getMinutes().toString().padStart(2, "0"); // 分
  var second = time.getSeconds().toString().padStart(2, "0"); // 秒
  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
};

const props = defineProps({
  tableData: {
    type: Object,
    default: [],
  },
  cloData: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["recall", "deleteD"]);
const recall = (e) => {
  emit("recall", e);
};
const deleteD = (e) => {
  emit("deleteD", e);
};
</script>
<style lang="scss"></style>
