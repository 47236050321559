<template>
  <div>
    <div class="searchValue">
      <search @seaCher="seaCher" @rest="rest" v-model:title="title" v-model:nick="nick" :showStatus="showStatus"></search>
    </div>
    <div>
      <dataTable :tableData="tableData" :cloData="cloData"></dataTable>
    </div>
    <div>
      <pagination :painData="painData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
import { getDiaryLists, searchDiaryLists } from "@/config/http.js"
import search from "./listComponents/search.vue"
import pagination from "@/components/pagination.vue"
import dataTable from "./listComponents/dataTable.vue"
import { ElMessage } from 'element-plus'
const tableData = ref()
const painData = reactive({
  total: 0,//一共多少条
  pageSize: 10, //一页多少条
  currentPage: 1, //第几页
})
const title = ref()
const nick = ref()
const showStatus = ref('img')
const cloData = reactive([
  {
    prop: "id",
    label: "ID"
  },
  {
    prop: "title",
    label: "标题"
  },
])
const getList = () => {
  const params = ({
    'page[number]': painData.currentPage,
    'page[size]': painData.pageSize
  })
  getDiaryLists(params).then((res) => {
    tableData.value = res.data
    painData.total = res.total
  })
}
const handleSizeChange = (val) => { //上一页
  painData.currentPage = val
  getList()
}
const handleCurrentChange = (val) => { //下一页
  painData.currentPage = val
  getList()
}
onMounted(() => {
  getList()
})
const seaCher = () => { //查询
  const params = {
    nick: nick.value,
    title: title.value
  }
  getDiaryLists(params).then((res) => {
    tableData.value = res.data
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: "查询错误",
    })
    title.value = ""
    nick.value = ""
    getList()
  })
}
const rest = () => { //重置
  title.value = ""
  nick.value = ""
  getList()
}
</script>
<style lang="scss">
.searchValue {
  display: flex;
}
</style>